<template>
  <div class="page-header border-bottom bg-white">
    <div
      class="back-button-row"
      v-if="props.showBackButton"
      :style="`${props.title ? 'padding-bottom: 0;' : ''}`"
    >
      <back-button :overrideBackButtonFunction="props.overrideBackButtonFunction" />
    </div>
    <div v-if="props.title" class="flex">
      <div class="flex flex-col p-6 w-2/3">
        <div class="flex">
          <h1 data-cy="page-header-title" class="page-header__title">
            {{ title }}
            <Button
              v-if="props.description || props.additionalDescription"
              outlined
              icon="pi pi-info-circle"
              class="!p-0"
              v-tooltip.bottom="{
                value: `${props.description ?? ''} ${props.additionalDescription ?? ''}`,
                escape: true,
              }"
            />
          </h1>
        </div>
        <p v-if="props.subtitle" class="page-header__subtitle mt-2">{{ props.subtitle }}</p>
      </div>
      <div class="flex justify-end items-start p-6 pt-7 w-2/3 gap-2">
        <slot name="buttons" />
      </div>
    </div>
    <div>
      <slot name="bottom" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import BackButton from '@/components/common/button/BackButton.vue';

const props = defineProps<{
  title?: string;
  subtitle?: string;
  description?: string;
  additionalDescription?: string;
  showBackButton?: boolean;
  overrideBackButtonFunction?: Function;
}>();
</script>

<style lang="scss" scoped>
.back-button-row {
  width: 100%;
  padding: 1rem;
}

.page-header {
  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    color: var(--gray-600);
    word-break: break-all;
  }

  &__description {
    font-size: 20px;
    line-height: 33px;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--gray-300);
  }
}
</style>
