<template>
  <v-chart :option="options" class="chart" autoresize />
</template>
<script setup lang="ts">
import VChart from 'vue-echarts';
import { computed } from 'vue';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import type { EChartsOption, SeriesOption } from 'echarts';
import type { FusedDataResponse } from '@/stores/admin/controlPanel/controlPanel.types';
import { convertValue } from '@/utils/units';
import { formatShortDateTime, formatDateTimeWithUsersTimezone } from '@/utils/dateTimeFormatVue';
import isArray from 'lodash/isArray';
import { cloneDeep } from 'lodash';
import { colorsPallette } from '@/utils/colors';

const props = defineProps<{
  originalUnit?: string | null;
  desiredUnit?: string | null;
  startUnixEpoch: number;
  endUnixEpoch: number;
  data: FusedDataResponse;
}>();

use([CanvasRenderer, LineChart, GridComponent, TooltipComponent]);

function zip<T, R>(a: T[], b: R[]): [T, R][] {
  return a.map((k, i) => [k, b[i]]);
}

function truncateIfNumber(value: any): any {
  if (typeof value === 'number' && value % 1 !== 0) return value.toFixed(3);
  return value;
}

function dataResponseToEchartsData(
  data: FusedDataResponse,
  minimalTimestamp?: Date,
  maximalTimestamp?: Date
): EChartsOption {
  let seriesData: SeriesOption[] = [];
  const xAxisFormatter = formatDateTimeWithUsersTimezone;
  const xData = data.x.data.map((v) => new Date(v * 1000).toISOString());
  let valueFormatter = (val: any, unit?: string | null) => `${truncateIfNumber(val)} ${unit || ''}`;

  let yAxis: any[] = [];
  let axisIndex = 0;

  for (var yAxisItem of data.y) {
    let yData: (number | null)[] = yAxisItem.data;

    const yDataWithoutNulls = yData.filter((value): value is number => value !== null);

    if (
      (yAxisItem.unit === '' || yAxisItem.unit) &&
      (yAxisItem.desiredUnit === '' || yAxisItem.desiredUnit)
    ) {
      let [convertedYData, convertedUnit] = convertValue(
        yDataWithoutNulls,
        yAxisItem.unit,
        yAxisItem.desiredUnit
      );

      let convertedYDataWithNulls: (number | null)[] = [];
      let j = 0;
      for (let i = 0; i < yData.length; i++) {
        if (yData[i] === null) {
          convertedYDataWithNulls.push(null);
        } else {
          convertedYDataWithNulls.push(convertedYData[j]);
          j++;
        }
      }

      yData = convertedYDataWithNulls;

      if (convertedUnit === 'Unknown unit') convertedUnit = '';

      const axisWithSameUnit = yAxis.findIndex((item) => item.unit === convertedUnit);

      if (axisWithSameUnit === -1) {
        console.log('axisIndex - initial:', axisIndex);

        axisIndex = yAxis.push({
          type: 'value',
          //@ts-ignore
          name: yAxisItem.label.replaceAll(' ', '\n'),
          unit: convertedUnit,
          //position: 'right',
          offset: axisIndex * 60,
          alignTicks: true,
          axisLine: {
            show: true,
            /*lineStyle: {
              color: colors[axisIndex+1]
            }*/
          },
          axisLabel: {
            formatter: (val: any) => valueFormatter(val, convertedUnit),
          },
        });
        //get index of new item not length of array
        axisIndex--;
      } else {
        axisIndex = axisWithSameUnit;
      }

      seriesData.push({
        name: yAxisItem.label,
        type: 'line',

        connectNulls: true,
        yAxisIndex: axisIndex,
        data: zip(xData, yData) as [],
      });
    }
  }

  const tooltipFormatter = (params: any) => {
    const param = isArray(params) ? params[0] : params;

    const series = seriesData[param.seriesIndex];
    //@ts-ignore
    const yAxisItem = yAxis[series.yAxisIndex];
    const unit = yAxisItem.unit;

    const tooltipHtml = `${xAxisFormatter(param.value[0])}<br/>${
      param.seriesName
    }<br/><i class="pi pi-circle-fill" style="color:${
      param.color
    };"></i> <span style="font-weight: bold">${valueFormatter(param.value[1], unit)}</span>`;
    return tooltipHtml;
  };

  const result = {
    xAxis: {
      type: 'time',
      min: minimalTimestamp,
      max: maximalTimestamp,
      data: xData,
      axisLabel: {
        hideOverlap: true,
        formatter: function (val: number) {
          return formatShortDateTime(new Date(val));
        },
      },
    },
    grid: {
      right: String(axisIndex * 64) + 'px',
    },
    dataZoom: [
      {
        type: 'slider',
      },
    ],
    tooltip: {
      trigger: 'item',
      confine: true,
      axisPointer: {
        type: 'line',
        axis: 'y',
        triggerTooltip: false,
      },
      formatter: tooltipFormatter,
    },
    yAxis: yAxis,
    series: seriesData,
    color: colorsPallette,
  };

  console.log('dataResponseToEchartsData', result);
  // @ts-ignore
  return result;
}

const options = computed(() =>
  dataResponseToEchartsData(
    cloneDeep(props.data),
    new Date(props.startUnixEpoch * 1000),
    new Date(props.endUnixEpoch * 1000)
  )
);
</script>
