<template>
  <Panel :header="`Connector status`" class="row-span-2 flex flex-col">
    <div v-if="loading" class="flex justify-center gap-1 items-center my-auto h-full px-4 py-5">
      <i class="pi pi-spin pi-spinner" />
      <p>{{ t('loading') }}</p>
    </div>
    <div
      v-else-if="error"
      class="flex justify-center gap-1 items-center my-auto h-full bg-gray-50 text-red-primary px-4 py-5"
    >
      <p>{{ error }}</p>
    </div>
    <div
      v-else-if="emptyData"
      class="flex justify-center gap-1 items-center my-auto h-full bg-gray-50 text-gray-500 px-4 py-5"
    >
      <exclamation-triangle-icon :style="{ height: '1rem', width: '1rem' }" />
      <p>{{ t('dashboard.telemetry.chart.nodata') }}</p>
    </div>
    <Timeline v-else :value="statusTimeline.reverse()" class="px-4 py-5">
      <template #marker="slotProps">
        <span
          :class="`p-1 rounded-full w-10 h-10 flex items-center justify-center ${
            slotProps.item.value === 'Error' ? 'bg-red-primary' : 'bg-green-primary'
          } text-white`"
        >
          <img :src="slotProps.item.icon" alt="icon" />
        </span>
      </template>
      <template #content="slotProps">
        <div class="flex flex-col">
          <div>
            <h3 class="font-medium text-md">
              {{ slotProps.item.value }}
              <!-- <i v-tooltip="`tooltip text...`" class="pi pi-info-circle ml-1"></i> -->
            </h3>
          </div>
          <p><date-time-display :date="secondsToMilliseconds(slotProps.item.timestamp)" /></p>
        </div>
      </template>
      <template #connector="slotProps">
        <div
          :class="`w-[2px] ${
            slotProps.item.value === 'Error' ? 'bg-red-primary' : 'bg-green-primary'
          } flex-grow`"
        ></div>
      </template>
    </Timeline>
  </Panel>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import Panel from 'primevue/panel';
import Timeline from 'primevue/timeline';

import type { DataResponse } from '@/stores/admin/controlPanel/controlPanel.types';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';

import iconReady from '/assets/icons/chargingStatus/circle-play.svg';
import iconDisabled from '/assets/icons/chargingStatus/circle-divide.svg';
import iconPluggedInWaitingAuth from '/assets/icons/chargingStatus/unplug.svg';
import iconPreparingCharging from '/assets/icons/chargingStatus/plug-zap.svg';
import iconCharging from '/assets/icons/chargingStatus/zap.svg';
import iconChargingStopping from '/assets/icons/chargingStatus/zap-off.svg';
import iconFinshed from '/assets/icons/chargingStatus/circle-check.svg';
import iconError from '/assets/icons/chargingStatus/triangle-alert.svg';
import iconNotPlugged from '/assets/icons/chargingStatus/plug.svg';
import iconStoppingChargingAuth from '/assets/icons/chargingStatus/zap-off.svg';
import iconAuthPayment from '/assets/icons/chargingStatus/credit-card.svg';
import iconReserved from '/assets/icons/chargingStatus/bookmark-check.svg';
import iconGracePeriod from '/assets/icons/chargingStatus/clock-arrow-up.svg';
import iconIdleTime from '/assets/icons/chargingStatus/clock-alert.svg';
import iconShowUserPrice from '/assets/icons/chargingStatus/circle-dollar-sign.svg';
import iconBooting from '/assets/icons/chargingStatus/circle-ellipsis.svg';
import iconUpdating from '/assets/icons/chargingStatus/circle-fading-arrow-up.svg';
import iconMaintenence from '/assets/icons/chargingStatus/wrench.svg';

type ChargingStatusTimeline = {
  timestamp: number;
  value: string | number;
  icon: string;
}[];

const { t } = useI18n();

const chargeStatesIcons = ref([
  {
    id: 0,
    icon: iconDisabled,
  },
  {
    id: 1,
    icon: iconReady,
  },
  {
    id: 2,
    icon: iconPluggedInWaitingAuth,
  },
  {
    id: 3,
    icon: iconPreparingCharging,
  },
  {
    id: 5,
    icon: iconCharging,
  },
  {
    id: 8,
    icon: iconChargingStopping,
  },
  {
    id: 9,
    icon: iconFinshed,
  },
  {
    id: 11,
    icon: iconError,
  },
  {
    id: 12,
    icon: iconNotPlugged,
  },
  {
    id: 50,
    icon: iconStoppingChargingAuth,
  },
  {
    id: 100,
    icon: iconAuthPayment,
  },
  {
    id: 101,
    icon: iconReserved,
  },
  {
    id: 102,
    icon: iconGracePeriod,
  },
  {
    id: 103,
    icon: iconIdleTime,
  },
  {
    id: 104,
    icon: iconShowUserPrice,
  },
  {
    id: 253,
    icon: iconBooting,
  },
  {
    id: 254,
    icon: iconUpdating,
  },
  {
    id: 255,
    icon: iconMaintenence,
  },
]);

const props = defineProps<{
  systemId: HardwareSystem['id'];
  chargePointId?: number;
  startTime: string;
  endTime: string;
  data: DataResponse[number] | undefined;
  enumValues?: { value: number; label: string }[];
  emptyData: boolean;
  error: string | undefined;
  loading: boolean;
}>();

function secondsToMilliseconds(timestamp: number) {
  return timestamp * 1000;
}

function convertEnumLabel(label: string | number) {
  let labelWithSpaces;
  let labelFirstUpperCase;

  if (typeof label === 'string') {
    labelWithSpaces = label.replace(/_/g, ' ');
    labelFirstUpperCase =
      labelWithSpaces.charAt(0).toUpperCase() + labelWithSpaces.slice(1).toLowerCase();
    return labelFirstUpperCase;
  } else {
    return label;
  }
}

function toStatusTimeline(data: DataResponse[number] | undefined): ChargingStatusTimeline {
  if (!data) return [];
  return data.x.data
    .map((timestamp, index) => ({
      timestamp,
      value: convertEnumLabel(
        props.enumValues?.find((enumValue) => enumValue.value === data.y.data[index])?.label ||
          data.y.data[index]
      ),
      icon: chargeStatesIcons.value.find((icon) => icon.id === data.y.data[index])?.icon || '',
    }))
    .sort((a, b) => b.timestamp - a.timestamp);
}

const statusTimeline = computed(() => toStatusTimeline(props.data));
</script>

<style scoped lang="scss">
:deep(.p-toggleable-content) {
  flex: 1;
}

:deep(.p-panel-content) {
  width: 100%;
  height: 100%;
  max-height: 374px;
  overflow-y: auto;
  padding: 0 !important;
}

:deep(.p-timeline-event) {
  min-height: 100px;
}

:deep(.p-timeline-event-opposite) {
  display: none;
}

@media screen and (min-width: 1300px) {
  :deep(.p-panel-content) {
    max-height: 806px;
  }
}
</style>
